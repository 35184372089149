<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { i18n } from "vue-lang-router";

export default {
    name: 'terms',

    data() {
        return {
            name: '',
            email: '',
        };
    },

    created() {
        document.title = "Tempus Bildbyrås köpvillkor";
    },

    computed: {
        ...mapGetters(['isLoading']),

    },

    methods: {
        send() {
            if (this.name == '' || this.email == '') {
                alert('Please complete all fields');
                return;
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(this.email)) {
                alert('Email field must have email form');
                return;
            }
            axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/newsletter/save`,
                { name: this.name, email: this.email }).then(() => {
                    this.name = "";
                    this.email = "";
                    alert('Saved');
                });
        },
    },

    components: {},
};
</script>

<template>
    <div class="illustration-info-container">
        <div class="illustration-info">
            <div class="content">
                <div class="header p-4">
                    <h1 class="text-3xl">Avtalsvillkor vid köp av digitala bilder från Tempus Bildbyrå</h1>
                </div>
                <br>
                <div class="content-paragraph">
                    <p>Dessa avtalsvillkor gäller vid köp av digitala bilder från Tempus Bildbyrå Aktiebolag (nedan kallad ”Bildbyrån”).</p>
                </div>
                <div class="content-paragraph">
                    <ol>
                        <li><b>Köpare:</b> Den fysiska eller juridiska person som köper bilder från Bildbyrån kallas ”Köparen”. Genom att beställa och betala för bilder godkänner Köparen dessa villkor. Om du genomför köpet för ett företag eller annan juridisk person intygar du att du har behörighet att göra detta å företagets vägnar. Du åtar dig även att informera berörda personer om dessa villkor.</li>
                        <li><b>Mellanhand:</b> Om Köparen agerar som mellanhand (till exempel reklam-, design- eller webbyrå) och köper bilder för användning i projekt för en uppdragsgivare skall uppdragsgivarens namn anmälas vid beställningen. Denna uppgift kommer att anges på faktura och kvitto. Köpta bilder får användas i projekt för endast en uppdragsgivare. Köparen ansvarar för att informera uppdragsgivaren om dessa avtalsvillkor och genom att godkänna detta avtal intygar Köparen att även uppdragsgivaren accepterar villkoren.</li>
                        <li><b>Betalning:</b> Betalning sker i förskott med bankkort eller banköverföring. Bilder levereras manuellt efter mottagen betalning. Återkommande företagskunder i Sverige kan ansöka om fakturakonto och köpa på kredit.</li>
                        <li><b>Ångerrätt:</b> Köparen kan ångra köpet fram till dess att bilden levererats. Efter leverans finns ingen ångerrätt för vare sig företags- eller privatkunder, då digitala bilder inte kan returneras och betalning avser förmedling, inte användning.</li>
                        <li><b>Bildkreditering:</b> Vid användning av bilder ska ”Tempus Bildbyrå” alltid anges som leverantör. I tryck anges Bildbyråns namn vid varje bild eller i en särskild bildförteckning. Vid digital publicering anges namnet antingen i bildens hörn eller i bildtexten.</li>
                        <li><b>Upphovspersoner:</b> Köparen bör respektera den ideella upphovsrätten och ange upphovspersonens namn vid publicering av bild.</li>
                        <li><b>Användning:</b> Köparen har rätt att använda köpta bilder i sin egen verksamhet eller för en uppdragsgivare enligt punkt 2 på valfritt sätt och på obestämd tid, med undantag för vad som föreskrivs i punkterna 8–10.</li>
                        <li><b>Begränsningar:</b> Bilder får inte användas på produkter som vykort, affischer eller liknande, där bilden blir en fristående produkt.</li>
                        <li><b>Upplösning på Internet:</b> Vid publicering på Internet får bilder användas i högst 72 ppi. Bilder får inte tillgängliggöras i hög upplösning på Internet.</li>
                        <li><b>Vidareförsäljning:</b> Köparen får inte sälja vidare eller överlåta bilder till tredje part, eller göra dem tillgängliga på Internet på ett sätt som gör att de för tredje part framstår som fria att kopiera eller ladda ned.</li>
                        <li><b>Rapportering:</b> Köparen har ingen skyldighet att redovisa bildanvändning, men Bildbyrån uppskattar återkoppling och tar gärna emot referensexemplar av trycksaker eller länkar till webbplatser där bilder publicerats.</li>
                        <li><b>Exklusivitet:</b> Köparen har inte exklusiv rätt till köpta bilder. Bilderna kan också vara tillgängliga från andra källor, eftersom de kommer från äldre publikationer som kan vara allmänt tillgängliga.</li>
                        <li><b>Bildernas skick:</b> Bilder säljs i befintligt skick. Köparen har möjlighet att granska lågupplösta versioner före köp och kan kontakta Bildbyrån vid behov av ytterligare hjälp. Det åligger Köparen att vid behov göra sådana korrigeringar i de digitala bilderna som krävs för ett gott resultat vid publicering. Bildbyrån ansvarar inte för resultat vid publicering i tryck eller i digital form och ersätter inte för eventuella skador till följd av otillfredsställande resultat.</li>
                        <li><b>Ansvarsbegränsning:</b> Bildbyrån ansvarar inte för felaktig bildtext, kategorisering eller metadata som anges på webbplatsen, och inte heller för tekniska fel, förseningar i leverans, eller avbrott på webbplatsen eller i andra digitala tjänster.</li>
                        <li><b>Skadeståndsansvar:</b> Bildbyråns eventuella skadeståndsansvar är alltid begränsat till det belopp Köparen betalat för bild.</li>
                        <li><b>Personuppgifter:</b> Bildbyrån lagrar Köparens personuppgifter och uppgifter om köp i sin databas och i bokföringen. I januari varje år raderar Bildbyrån beställningar (med tillhörande personuppgifter) i databasen som är äldre än sju år.</li>
                        <li><b>Betalningstjänster:</b> Bildbyrån använder Stripe, Inc. för betalning med bankkort. Stripe lagrar transaktionsuppgifter i enlighet med gällande lagstiftning.</li>
                        <li><b>Cookies:</b> Webbplatsen använder cookies för kundvagn och kassa (nödvändiga cookies), samt för att spara användarinställningar och analysera trafik. Användaren kan själv välja vilka cookies som, utöver de nödvändiga, skall tillåtas. Detta val kan när som helst ändras och det görs genom att gå in på sidan Kontakt i menyn.</li>
                        <li><b>Avtalstillägg:</b> Ändrade eller utökade villkor som överenskommits mellan Bildbyrån och Köparen anges på faktura eller kvitto och gäller i de fallen. I det fall villkor på faktura eller kvitto är motstridigt dessa köpvillkor gäller villkor på faktura eller kvitto.</li>
                        <li><b>Ändringar i köpvillkor:</b> Bildbyrån förbehåller sig rätten att när som helst ändra köpvillkoren. De nya villkoren gäller då för framtida köp.</li>
                        <li><b>Tvistelösning:</b> Tvist rörande dessa avtalsvillkor skall avgöras av Södertörns tingsrätt och enligt svensk lag.</li>
                    </ol>
                </div>
                <div class="content-paragraph">
                    <p>Köpvillkoren fastställdes den 1 november 2024.</p>
                </div>
        </div>
    </div>
</div></template>

<style lang="scss" scoped>.illustration-info-container {
    text-align: left;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.05);

    .illustration-info {
        margin: 0 25%;
        // height: 100vh;
        background-color: #fff;

        @media only screen and (max-width: 992px) {
            margin: 0 20px;
            width: 100%;
            height: 100%;
        }

        @media only screen and (max-width: 600px) {
            width: auto;
        }

        // @media screen and (max-width: $bp-mobile) {
        //   width: 100%;
        //   margin: 0;
        // }

        .header {
            span {
                margin: 0 1em;
                cursor: pointer;
            }

            h3 {
                padding: 1em;
            }
        }

        .content {
            padding: 1em;

            h1 {
                font-size: 30px;
                font-weight: 500;
                line-height: 36px;
            }

            h2 {
                font-size: 22px;
                font-weight: 500;
                line-height: 36px;
            }

            .content-paragraph {
                font-size: 16px;
                font-weight: 500;
                line-height: 29px;

                ul {
                    list-style-type: disc;
                    padding-left: 20px;
                }

                a {
                    text-decoration: underline;
                    color: #1c1c68;
                }

                ol{
                    list-style-type: auto;
                    padding-left: 20px;
                }
            }

            .split-content {
                display: flex;
                flex-direction: row;
                gap: 30px;

                img {
                    width: 40%;
                }

                @media only screen and (max-width: 992px) {
                    flex-direction: column;

                    img {
                        width: 100%;
                    }
                }


            }

            div {
                padding: 1em;
            }

            .about {
                p {
                    margin: 0;
                    font-size: 0.9em;
                    line-height: 1.4em;
                }
            }

            .category {
                div {
                    padding: 0;
                    margin: 1em 0;

                    &:last-of-type {
                        margin-bottom: 0;
                        border-bottom: none;
                    }
                }
            }

            .description {
                background-color: #dfe6e9;
                padding-left: 0;
                padding-right: 0;
            }

            .tags {
                display: block;

                .badge {
                    display: inline-block;
                    font-size: 0.75em;
                    padding: 0.5em 0.75em;
                    border-radius: 5px;
                    color: #fff;
                    margin: 0 0.5em 0.5em 0;
                    background-color: #1c1c68;
                }
            }
        }
    }


}</style>
